import AlertPrimarySvg from "./alertPrimary";
import AlertSecondarySvg from "./alertSecondary";
import AppStoreSvg from "./appStore";
import ChatPrimarySvg from "./chatPrimary";
import ChatSecondarySvg from "./chatSecondary";
import PlayStoreSvg from "./playStore";

export const SVG_ICON = {
  AppStore: (props) => <AppStoreSvg {...props} />,
  PlayStore: (props) => <PlayStoreSvg {...props} />,
  AlertPrimary: (props) => <AlertPrimarySvg {...props} />,
  AlertSecondary: (props) => <AlertSecondarySvg {...props} />,
  ChatPrimary: (props) => <ChatPrimarySvg {...props} />,
  ChatSecondary: (props) => <ChatSecondarySvg {...props} />,
};
