import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/scss/main.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./store/store";
import RouteStack from "./routes";
import { authSuccess } from "./store/auth/action";
import LoadingSplash from "./components/LoadingSplash";
import { makeGetCall } from "./utils/Requests";
import apiConfig from "./utils/endpoints";
import LoadingLayer from "./components/ConnectUserProfile/LoaderLayer";
import { getGeolocation } from "./constants/helperConnectUser";
import { updateUserLocation } from "./utils";
import AddReviewModal from "./components/AddReviewModal";
import { handleData } from "./constants/helperOnboarding";
import usePreventShortcut from "./utils/usePreventShortcut";
import DownloadAppPrompt from './components/DownloadAppPrompt';

const UpdateLocationWorker = new Worker(new URL("./Workers/UpdateLocationWorker.js", import.meta.url));

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isUserLogin, setIsUserLogin] = useState(true);
  const [reviewList, setReviewList] = useState(null);
  const [reviewModal, setReviewModal] = useState(false);
  usePreventShortcut(); 
  const handleCloseReviewModal = () => {
    setReviewModal(false);
  };

  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const getReviewList = () => {
    setTimeout(()=>{
    makeGetCall({ url: apiConfig.review })
      .then(async (res) => {
        setReviewList(res?.results);
        setReviewModal(true);
      })
      .catch((err) => {
        console.error("err: ", err);
      });
    }, 10000 )
  };

  const fetchUserData = async () => {
    const fm_token = localStorage.getItem("fm_token");
    if (!fm_token) {
      setIsUserLogin(false);
      return;
    }

    try {
      // Fetch user profile
      const res = await makeGetCall({ url: apiConfig.profile });
      // Dispatch user authentication details
      dispatch(
        authSuccess({
          token: fm_token,
          moreUserDetails: handleData(res?.data?.userDetail),
          loggedIn: true,
          businessDetail:res?.data?.businessDetail
        })
      );
      setIsUserLogin(false);
      
      // Get user geolocation
      const locationData: any = await getGeolocation();
      const { currentLng, currentLat } = locationData;

      // Update user location if coordinates are available
      if (currentLng !== null && currentLat !== null) {
        // updateUserLocation(currentLat, currentLng);

        UpdateLocationWorker?.postMessage({
          lat: currentLat,
          long: currentLng,
          url: "auth/location/",
          token: fm_token,
        });

        // Listen for responses from the worker
        UpdateLocationWorker.onmessage = (e) => {
          const { error } = e.data;

          if (error) {
            console.error("Failed to update location:", error);
          }
        };
      }
      await getReviewList();

    } catch (err) {
      console.error("Error fetching user data: ", err);
    } finally {
      // Ensure the login state is updated after fetching or error
      setIsUserLogin(false);
    }
  };
  
  useEffect(() => {
    fetchUserData();
  }, []);

  const queryParams = new URLSearchParams(location.search);
  const attribute = queryParams.get("attribute");
  if (attribute) {
    localStorage.setItem("attribute", attribute);
    navigate(`/`);
  }

  return (
    <React.Fragment>
      {isUserLogin ? (
        <LoadingSplash />
      ) : (
        <>
          {auth?.loading && <LoadingLayer />}
          <RouteStack />
        </>
      )}
      <DownloadAppPrompt />
      {reviewModal && reviewList?.length > 0 && (
        <AddReviewModal
          data={reviewList[0]}
          open={reviewModal}
          onClose={handleCloseReviewModal}
        />
      )}
      <ToastContainer />
    </React.Fragment>
  );
};

export default App;
