import { ROUTE_CONSTANTS } from "../utils/RouteConstants";

export const stepsNavigation = (nav, authData) => {
  const auth = authData?.userDetail;
  if (auth?.isProfileCompleted) {
    return nav(ROUTE_CONSTANTS?.landing);
  } else {
    if (
      !auth?.userType ||
      (auth?.userType === "Guest" && Number(auth?.onboardingStep) === 1)
    ) {
      return nav(ROUTE_CONSTANTS?.welcome);
    } else {
      if (auth?.userType === "Member") {
        if (auth?.isTrainer || auth?.isBusiness || auth?.isIndividual) {
          return nav(ROUTE_CONSTANTS?.manage_profile);
        } else {
          return nav(ROUTE_CONSTANTS?.select_member_type);
        }
      } else {
        return nav(ROUTE_CONSTANTS?.manage_profile);
      }
    }
  }
};

interface RespType {
  [key: string]: any;
}
export const handleData = (resp: RespType = {}): { [key: string]: any } => {
  const data = {
    id: resp?.id ?? null,
    userType: resp?.userType ?? null,
    email: resp?.email ?? null,
    isIndividual: resp?.isIndividual ?? false,
    isBusiness: resp?.isBusiness ?? false,
    isTrainer: resp?.isTrainer ?? false,
    onboardingStep: resp?.onboardingStep ?? null,
    username: resp?.username ?? null,
    name: resp?.name ?? null,
    bio: resp?.bio ?? null,
    gender: resp?.gender ?? null,
    profilePicture: resp?.profilePicture ?? null,
    thumbnail: resp?.thumbnail ?? null,
    profilePictureExtension: resp?.profilePictureExtension ?? null,
    mobileNumber: resp?.mobileNumber ?? null,
    dob: resp?.dob ?? null,
    isProfileCompleted: resp?.isProfileCompleted ?? false,
    listingBalance: resp?.listingBalance ?? 0,
    unreadNotificationCount: resp?.unreadNotificationCount ?? 0,
    unreadMessagesCount: resp?.unreadMessagesCount ?? 0,
    coverPhotoVideo: resp?.coverPhotoVideo ?? null,
    coverExtension: resp?.coverExtension ?? null,
    canCreateListing: resp?.canCreateListing ?? true,
    hasUsedFreeTrial: resp?.hasUsedFreeTrial ?? false,
    merchantFee: resp?.merchantFee ?? 0,
    isEmailVerified: resp?.isEmailVerified ?? false,
    totalEarnings: resp?.totalEarnings ?? 0,
    socialLinks: resp?.socialLinks ?? null,
    flagFee: resp?.flagFee ?? 0,
  };

  return data;
};
