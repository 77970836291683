import { useEffect } from "react";

const usePreventShortcut = () => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.metaKey || event.ctrlKey) && // Cmd (Mac) or Ctrl (Windows)
        event.shiftKey && // Shift key
        event.code === "Space" // Space key
      ) {
        event.preventDefault();
        event.stopPropagation();
        console.log("Cmd + Shift + Space (or Ctrl + Shift + Space) was blocked.");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
};

export default usePreventShortcut;
