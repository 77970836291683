import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ConnectState {
  selectedUserId: string | null;
}

const initialState: ConnectState = {
  selectedUserId: null,
};

const connectSlice = createSlice({
  name: "connect",
  initialState,
  reducers: {
    setSelectedUserId: (state, action: PayloadAction<string>) => {
      state.selectedUserId = action.payload;
    },
    clearSelectedUserId: (state) => {
      state.selectedUserId = null;
    },
  },
});

export const { setSelectedUserId, clearSelectedUserId } = connectSlice.actions;
export default connectSlice.reducer;
