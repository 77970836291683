import React from "react";

type SvgIconProps = {
  width?: string | number;
  height?: string | number;
  fill?: string;
  className?: string;
};

const ChatPrimarySvg: React.FC<SvgIconProps> = ({
  width = 127,
  height = 55,
  fill = "none",
  className = "",
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Chat">
        <path
          id="Shape"
          d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C10.3596 22 8.77516 21.6039 7.35578 20.8583L3.06538 21.9753C2.6111 22.0937 2.1469 21.8213 2.02858 21.367C1.99199 21.2266 1.99198 21.0791 2.02855 20.9386L3.1449 16.6502C2.3972 15.2294 2 13.6428 2 12C2 6.47715 6.47715 2 12 2ZM13.2517 13H8.75L8.64823 13.0068C8.28215 13.0565 8 13.3703 8 13.75C8 14.1297 8.28215 14.4435 8.64823 14.4932L8.75 14.5H13.2517L13.3535 14.4932C13.7196 14.4435 14.0017 14.1297 14.0017 13.75C14.0017 13.3703 13.7196 13.0565 13.3535 13.0068L13.2517 13ZM15.25 9.5H8.75L8.64823 9.50685C8.28215 9.55651 8 9.8703 8 10.25C8 10.6297 8.28215 10.9435 8.64823 10.9932L8.75 11H15.25L15.3518 10.9932C15.7178 10.9435 16 10.6297 16 10.25C16 9.8703 15.7178 9.55651 15.3518 9.50685L15.25 9.5Z"
          fill="url(#paint0_linear_6173_99074)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_6173_99074"
          x1="-1.64103"
          y1="-0.974378"
          x2="7.20687"
          y2="37.554"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EE7830" />
          <stop offset="1" stopColor="#EE762F" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ChatPrimarySvg;
