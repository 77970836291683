import React from "react";

type SvgIconProps = {
  width?: string | number;
  height?: string | number;
  fill?: string;
  className?: string;
};

const ChatSecondarySvg: React.FC<SvgIconProps> = ({
  width = 127,
  height = 55,
  fill = "none",
  className = "",
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C8.35956 20 6.77516 19.6039 5.35578 18.8583L1.06538 19.9753C0.611098 20.0937 0.146904 19.8213 0.0285757 19.367C-0.00801405 19.2266 -0.00801832 19.0791 0.0285473 18.9386L1.1449 14.6502C0.397199 13.2294 0 11.6428 0 10C0 4.47715 4.47715 0 10 0ZM11.2517 11H6.75L6.64823 11.0068C6.28215 11.0565 6 11.3703 6 11.75C6 12.1297 6.28215 12.4435 6.64823 12.4932L6.75 12.5H11.2517L11.3535 12.4932C11.7196 12.4435 12.0017 12.1297 12.0017 11.75C12.0017 11.3703 11.7196 11.0565 11.3535 11.0068L11.2517 11ZM13.25 7.5H6.75L6.64823 7.50685C6.28215 7.55651 6 7.8703 6 8.25C6 8.6297 6.28215 8.94349 6.64823 8.99315L6.75 9H13.25L13.3518 8.99315C13.7178 8.94349 14 8.6297 14 8.25C14 7.8703 13.7178 7.55651 13.3518 7.50685L13.25 7.5Z"
        fill="white"
      />
    </svg>
  );
};

export default ChatSecondarySvg;
