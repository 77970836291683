import React from "react";

type SvgIconProps = {
  width?: string | number;
  height?: string | number;
  fill?: string;
  className?: string;
};

const AlertSecondarySvg: React.FC<SvgIconProps> = ({
  width = 127,
  height = 55,
  fill = "none",
  className = "",
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.03978 19.003L14.9563 19.003C14.7177 20.4211 13.484 21.5015 11.9981 21.5015C10.5121 21.5015 9.27842 20.4211 9.03978 19.003ZM11.9981 2.00391C16.1402 2.00391 19.4981 5.36177 19.4981 9.50391L19.4981 13.5024L20.9164 16.6625C20.9713 16.7848 20.9997 16.9174 20.9997 17.0515C20.9997 17.5762 20.5743 18.0015 20.0497 18.0015L3.95024 18.0015C3.81645 18.0015 3.68417 17.9733 3.56207 17.9186C3.08319 17.7042 2.86878 17.1422 3.08316 16.6633L4.49805 13.5028L4.49816 9.49099L4.50258 9.24107C4.64165 5.21035 7.95392 2.00391 11.9981 2.00391Z"
        fill="white"
      />
    </svg>
  );
};

export default AlertSecondarySvg;
