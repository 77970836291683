import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useCheckDeviceScreen from '../../hooks/useCheckScreen';
import { SVG_ICON } from '../../assets/svg';

const DownloadAppPrompt = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { isMobile } = useCheckDeviceScreen();
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    // Detect iOS device
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    setIsIOS(iOS);
  }, []);

  if (!isMobile || !isOpen) return null;

  const handleStoreClick = () => {
    if (isIOS) {
      window.open(
        "https://apps.apple.com/in/app/fitness-mates-pty-ltd/id6449068527",
        "_blank"
      );
    } else {
      window.open(
        "https://play.google.com/store/apps/details?id=com.fitnessmates.mobile.app",
        "_blank"
      );
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: '#25272D',
        color: 'white',
        px: 2,
        py: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        zIndex: 1600,
        gap: 2,
        boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' ,  flex: 1, gap: 2 }}>
        <Typography variant="body1" sx={{ mr: 1, fontSize: '14px' }}>
        Enhance Your Experience! 
          <br/>
          Get the App 
        </Typography>
        <Box 
          onClick={handleStoreClick}
          sx={{ 
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            width: '100px',
            height: '40px'
          }}
        >
          {isIOS ? (
            <SVG_ICON.AppStore width={100} height={40} />
          ) : (
            <SVG_ICON.PlayStore width={100} height={40} />
          )}
        </Box>
      </Box>
      <IconButton
        onClick={() => setIsOpen(false)}
        sx={{ color: 'white' }}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default DownloadAppPrompt;
